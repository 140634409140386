<template>
  <div>
    <b-row class="my-4">
      <b-col cols="8">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.schedule') }}
        </h3>

        <router-link :to="{ name: 'events-schedule-create' }">
          <b-button size="sm" class="ml-3" variant="success">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-plus-circle-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5
                4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5
                0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
              />
            </svg>
            {{ $t('buttons.add') }}
          </b-button>
        </router-link>
      </b-col>
      <b-col cols="4" class="d-flex justify-content-end">
        <div v-if="bulkEdit">
          <b-button
            size="sm"
            class="ml-3"
            variant="success"
            @click="onBulkSubmit"
          >
            {{ $t('buttons.saveall') }}
          </b-button>
          <b-button
            size="sm"
            class="ml-3"
            variant="danger"
            @click="onBulkReset"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
        </div>
        <div v-else>
          <b-button
            size="sm"
            class="ml-3"
            variant="info"
            @click="bulkEdit = true"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5
                0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
            {{ $t('buttons.editall') }}
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col lg="6" class="mb-sm-2">
        <b-input-group>
          <b-form-input
            v-model="filter"
            type="search"
            :placeholder="$t('inputs.typeSearch')"
          />

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              {{ $t('buttons.clear') }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- FILTER -->
    <b-row class="mb-3">
      <b-col lg="3" sm="12">
        <b-form-select v-model="isMasterclass" class="mr-3">
          <b-form-select-option :value="null">
            Masterclass?
          </b-form-select-option>
          <b-form-select-option value="true">
            {{ $t('general.yes') }}
          </b-form-select-option>
          <b-form-select-option value="false">
            {{ $t('general.no') }}
          </b-form-select-option>
        </b-form-select>
      </b-col>

      <b-col lg="3" sm="12">
        <b-form-select v-model="stageListFilter" :options="stageList" class="mr-3"></b-form-select>
      </b-col>

      <b-col lg="3" sm="12">
        <b-form-select v-model="trackFilter" :options="trackOpts" class="mr-3"></b-form-select>
      </b-col>

      <b-col lg="3" sm="12" class="text-right">
        <p class="mb-0">
          {{ $t('eventSchedule.registers', { num: totalRows }) }}
        </p>
      </b-col>
    </b-row>

    <b-table
      show-empty
      responsive
      bordered
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :busy="loading"
      :items="data"
      :fields="fields"
      :emptyText="$t('messages.emptyTable')"
      :emptyFilteredText="$t('messages.emptyFilteredTable')"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div
          class="
            text-center text-danger
            my-2
            align-items-center
            d-flex
            flex-column
          "
        >
          <b-spinner
            class="align-middle flex-direction-column mb-3"
          ></b-spinner>
          <strong>{{ $t('messages.loading') }}...</strong>
        </div>
      </template>

      <template v-slot:cell(nameEN)="data">
        <p class="text-left m-0" style="max-width: 500px">{{ data.value }}</p>
      </template>

      <template v-slot:cell(namePT)="data">
        <p class="text-left m-0" style="max-width: 500px">{{ data.value }}</p>
      </template>

      <template v-slot:cell(isPublished)="data">
        <p class="text-center m-0">
          <b-icon
            v-if="data.value"
            icon="check-circle-fill"
            variant="success"
          />
          <b-icon v-else icon="x-circle-fill" variant="danger" />
        </p>
      </template>

      <template v-slot:cell(date)="data">
        <form
          v-if="isEditing(data.value.key) || bulkEdit"
          @submit.prevent="onSubmit(data.item.id)"
          @reset="clearEdit(data.item.model, data.item.id)"
          @keydown.esc="clearEdit(data.item.model, data.item.id)"
        >
          <b-input-group>
            <b-form-select
              v-model="forms[data.item.id].dateId"
              size="sm"
              :options="daysOpts"
              @change="val => onChange('dateId', val, data.item.id)"
            />

            <b-input-group-append v-if="!bulkEdit">
              <b-button
                size="sm"
                variant="outline"
                class="p-1"
                type="submit"
                :disabled="loading"
              >
                <b-icon icon="check-square-fill" variant="success" />
              </b-button>

              <b-button
                size="sm"
                variant="outline"
                class="p-1"
                :disabled="loading"
                type="reset"
              >
                <b-icon icon="x-square-fill" variant="danger" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </form>

        <div
          v-else
          v-on:dblclick="setEdit(data.value.key)"
          class="align-items-center d-flex"
        >
          <p class="text-left m-0">
            {{ data.value.date }}
          </p>
          <b-button
            size="sm"
            variant="outline"
            @click="setEdit(data.value.key)"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5
                0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(schedule)="data">
        <form
          v-if="isEditing(data.value.key) || bulkEdit"
          @submit.prevent="onSubmit(data.item.id)"
          @reset="clearEdit(data.item.model, data.item.id)"
          @keydown.esc="clearEdit(data.item.model, data.item.id)"
        >
          <b-input-group>
            <b-form-input
              type="time"
              size="sm"
              input-class="form-control"
              v-model="forms[data.item.id].startTime"
              @change="val => onChange('startTime', val, data.item.id)"
            />

            <b-form-input
              type="time"
              size="sm"
              input-class="form-control"
              v-model="forms[data.item.id].endTime"
              :disabled="!forms[data.item.id].startTime"
              :min-datetime="forms[data.item.id].startTime"
              @change="val => onChange('endTime', val, data.item.id)"
            />
            <b-input-group-append v-if="!bulkEdit">
              <b-button
                size="sm"
                variant="outline"
                class="p-1"
                type="submit"
                :disabled="loading"
              >
                <b-icon icon="check-square-fill" variant="success" />
              </b-button>

              <b-button
                size="sm"
                variant="outline"
                class="p-1"
                :disabled="loading"
                type="reset"
              >
                <b-icon icon="x-square-fill" variant="danger" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </form>

        <div
          v-else
          v-on:dblclick="setEdit(data.value.key)"
          class="align-items-center d-flex"
        >
          <p class="text-left m-0">
            {{ data.value.startTime }} - {{ data.value.endTime }}
          </p>
          <b-button
            size="sm"
            variant="outline"
            @click="setEdit(data.value.key)"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5
                0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(stage)="data">
        <form
          v-if="isEditing(data.value.key) || bulkEdit"
          @submit.prevent="onSubmit(data.item.id)"
          @reset="clearEdit(data.item.model, data.item.id)"
          @keydown.esc="clearEdit(data.item.model, data.item.id)"
        >
          <b-input-group>
            <b-form-select
              size="sm"
              name="stage"
              v-model="forms[data.item.id].stage"
              :options="data.value.stageOpts"
              @change="val => onChange('stage', val, data.item.id)"
            />

            <b-input-group-append v-if="!bulkEdit">
              <b-button
                size="sm"
                variant="outline"
                class="p-1"
                type="submit"
                :disabled="loading"
              >
                <b-icon icon="check-square-fill" variant="success" />
              </b-button>

              <b-button
                size="sm"
                variant="outline"
                class="p-1"
                :disabled="loading"
                type="reset"
              >
                <b-icon icon="x-square-fill" variant="danger" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </form>

        <div
          v-else
          v-on:dblclick="setEdit(data.value.key)"
          class="align-items-center d-flex"
        >
          <p class="text-left m-0">{{ data.value.name }}</p>

          <b-button
            size="sm"
            variant="outline"
            @click="setEdit(data.value.key)"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                  0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                  0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5
                  0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                  0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                  1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(track)="data">
        <form
          v-if="isEditing(data.value.key) || bulkEdit"
          @submit.prevent="onSubmit(data.item.id)"
          @reset="clearEdit(data.item.model, data.item.id)"
          @keydown.esc="clearEdit(data.item.model, data.item.id)"
        >
          <b-input-group>
            <b-form-select
              size="sm"
              name="track"
              v-model="forms[data.item.id].track"
              :options="data.value.trackOpts"
              @change="val => onChange('track', val, data.item.id)"
            />

            <b-input-group-append v-if="!bulkEdit">
              <b-button
                size="sm"
                variant="outline"
                class="p-1"
                type="submit"
                :disabled="loading"
              >
                <b-icon icon="check-square-fill" variant="success" />
              </b-button>

              <b-button
                size="sm"
                variant="outline"
                class="p-1"
                :disabled="loading"
                type="reset"
              >
                <b-icon icon="x-square-fill" variant="danger" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </form>

        <div
          v-else
          v-on:dblclick="setEdit(data.value.key)"
          class="align-items-center d-flex"
        >
          <p class="text-left m-0">{{ data.value.name }}</p>

          <b-button
            size="sm"
            variant="outline"
            @click="setEdit(data.value.key)"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                  0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                  0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5
                  0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                  0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                  1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <p class="text-center m-0">
          <b-button
            size="sm"
            variant="info"
            @click="
              $router.push({
                name: 'events-schedule-edit',
                params: { id: data.item.id }
              })
            "
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5
                0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
            {{ $t('buttons.edit') }}
          </b-button>

          <span class="mx-1" />

          <b-button
            size="sm"
            variant="danger"
            @click="
              deleteModal = true;
              deleteId = data.item.id;
            "
          >
            <b-icon icon="trash" />
            {{ $t('buttons.delete') }}
          </b-button>
        </p>
      </template>
    </b-table>

    <b-row class="text-center mt-4" v-if="totalRows > perPage">
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        />
      </b-col>
    </b-row>

    <b-modal
      centered
      v-model="deleteModal"
      @ok="onDelete"
      :ok-title="$t('buttons.delete').toUpperCase()"
      :cancel-title="$t('buttons.cancel').toUpperCase()"
      ok-variant="danger"
    >
      <div class="d-block">
        {{ $t('messages.deleteConfirmMessage') }}
        <strong>{{ $t('messages.deleteConfirmMessageAlert') }}</strong>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { DateTime as luxon } from 'luxon';
import EventsSchedule from '../../../../services/EventsSchedule';
import EventsStage from '../../../../services/EventsStage';
import EventsDates from '../../../../services/EventsDates';

import Toast from '../../../../assets/js/toast';
import 'vue-datetime/dist/vue-datetime.css';

export default {
  data() {
    const data = {
      list: [],
      forms: {},
      filter: '',
      bulkEdit: false,
      editingId: null,

      totalRows: 0,
      loading: true,
      deleteModal: false,
      deleteId: null,
      currentPage: 1,
      perPage: 50,
      isMasterclass: null,
      trackFilter: null,
      stageListFilter: null,

      daysOpts: [],
      trackOpts: [
        {
          value: null,
          text: 'Track',
        },
        {
          value: 'track1',
          text: 'Track 1',
        },
        {
          value: 'track2',
          text: 'Track 2',
        },
        {
          value: 'track3',
          text: 'Track 3',
        },
      ],

      fields: [
        {
          key: this.$i18n.locale === 'en' ? 'nameEN' : 'namePT',
          label: this.$t('inputs.name'),
          sortable: true,
        },
        {
          key: 'isPublished',
          label: this.$t('inputs.published'),
          sortable: true,
        },
        { key: 'date', label: this.$t('eventSchedule.date'), sortable: true },
        { key: 'schedule', label: this.$t('inputs.period'), sortable: true },
        { key: 'stage', label: this.$t('inputs.stage'), sortable: true },
        { key: 'track', label: 'Track', sortable: true },
        { key: 'actions', label: this.$t('inputs.action') },
      ],
    };
    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      try {
        this.loading = true;
        const xhr = await EventsSchedule.get();

        const [stages] = await Promise.all([EventsStage.get()]);

        const [dates] = await Promise.all([EventsDates.get()]);

        this.stageList = [
          {
            value: null,
            text: this.$t('inputs.stage'),
          },
          ...stages.map(
            ({ id: value, name: text, isMasterclass }) => ({
              value,
              text,
              isMasterclass,
            }),
          ),
        ];

        this.daysOpts = dates.map((opt) => ({
          value: opt.id,
          text: this.formatDate(opt.date),
        }));

        this.list = xhr.map((item) => {
          const model = {
            stage: item.stage.id,
            dateId: item.dateId,
            track: item.track,
            startTime: this.formatTime(item.startTime),
            endTime: this.formatTime(item.endTime),
          };

          this.forms = {
            ...this.forms,
            [item.id]: {
              stage: item.stage.id,
              dateId: item.dateId,
              track: item.track,
              startTime: this.formatTime(item.startTime),
              endTime: this.formatTime(item.endTime),
            },
          };

          const date = {
            date: this.formatDate(item.date.date),
            dateId: item.date.id,
            key: `${item.id}--date`,
          };

          const schedule = {
            startTime: this.formatTime(item.startTime),
            endTime: this.formatTime(item.endTime),
            key: `${item.id}--schedule`,
          };

          const stage = {
            name: item.stage ? item.stage.name : '',
            id: item.stage ? item.stage.id : '',
            stageOpts: this.stageList.filter(
              (o) => o.isMasterclass === item.isMasterclass && o.value !== null,
            ),
            key: `${item.id}--stage`,
          };

          const {
            value: trackid,
            text: trackname,
          } = this.trackOpts.filter((i) => i.value === item.track)[0];

          const track = {
            name: trackname,
            id: trackid,
            trackOpts: this.trackOpts.filter((i) => i.value !== null),
            key: `${item.id}--track`,
          };

          const returnItem = {
            id: item.id,
            isMasterclass: item.isMasterclass,
            isPublished: item.isPublished,
            nameEN: item.nameEN,
            namePT: item.namePT,
            date,
            schedule,
            stage,
            track,
            model,
          };

          return returnItem;
        });

        this.totalRows = this.data.length;
      } catch (e) {
        this.loading = true;
        // eslint-disable-next-line no-console
        console.log('ERROR', e);

        Sentry.captureException(e);
      } finally {
        this.editingId = null;
        this.loading = false;
      }
    },

    async onSubmit(id) {
      try {
        this.loading = true;
        let res;
        const xhr = await EventsSchedule.getById(id);
        if (xhr) {
          res = await EventsSchedule.update(
            {
              ...xhr,
              ...this.forms[id],
              date: this.forms[id].dateId || xhr.date,
              dateId: this.forms[id].dateId || xhr.date,
              talent: xhr.talents.map((item) => item.id),
            },
            id,
          );
        }

        if (res.conflict) Toast.warn(this, 'messages.scheduleConflict');
        else Toast.success(this, 'messages.saveSuccess');
      } catch (e) {
        this.loading = true;
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.forms[id], undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.editingId = null;
        this.loading = false;

        this.onLoad();
      }
    },

    async onDelete() {
      try {
        this.loading = true;

        await EventsSchedule.delete(this.deleteId);

        Toast.success(this, 'messages.removeSuccess');
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('deleteId', this.deleteId);
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.deleteId = null;
        this.onLoad();
      }
    },

    async onBulkSubmit() {
      this.loading = true;
      const changed = this.list.filter((item) => this.hasDiff(item.model, this.forms[item.id]));
      const conflictedRes = [];

      if (changed.length <= 0) {
        this.bulkEdit = false;
        this.editingId = null;
        this.loading = false;
        return;
      }

      try {
        await Promise.all(
          changed.map(async (item) => {
            const xhr = await EventsSchedule.getById(item.id);
            const res = await EventsSchedule.update(
              {
                ...xhr,
                ...this.forms[item.id],
                date: this.forms[item.id].dateId || xhr.date,
                dateId: this.forms[item.id].dateId || xhr.date,
                talent: xhr.talents.map((talent) => talent.id),
              },
              item.id,
            );

            if (res.conflict) {
              conflictedRes.push({
                name: this.$i18n.locale === 'en' ? item.nameEN : item.namePT,
                form: this.forms[item.id],
              });
            }
          }),
        );

        if (conflictedRes.length > 0) Toast.warn(this, 'messages.scheduleConflict');
        else Toast.success(this, 'messages.saveSuccess');
      } catch (e) {
        this.loading = true;
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(conflictedRes, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.bulkEdit = false;
        this.editingId = null;

        this.onLoad();
        this.loading = false;
      }
    },

    async onBulkReset() {
      this.loading = true;

      await this.list.map((item) => {
        const { model, id } = item;

        this.forms[id] = model;

        return this.model;
      });

      this.bulkEdit = false;
      this.editingId = null;

      this.loading = false;
    },

    setEdit(id) {
      this.editingId = id;
    },

    isEditing(id) {
      return this.editingId === id;
    },

    clearEdit(model, id) {
      this.forms[id] = model;
      this.editingId = null;
    },

    onChange(field, val, id) {
      const modify = {
        ...this.forms[id],
        [field]: val,
      };
      this.forms[id] = modify;
    },

    onFiltered() {
      this.currentPage = 1;
    },

    formatDate(date) {
      try {
        return luxon
          .fromISO(date)
          .setLocale(this.$i18n.locale)
          .toLocaleString(luxon.DATE_SHORT);
      } catch (e) {
        Toast.error(e);
        return date;
      }
    },

    formatTime(tm) {
      try {
        return luxon
          .fromISO(tm)
          .setLocale(this.$i18n.locale)
          .toLocaleString(luxon.TIME_24_SIMPLE);
      } catch (e) {
        return tm;
      }
    },

    hasDiff(model, form) {
      let diff = false;

      Object.keys(form).map((key) => {
        if (model[key] !== form[key]) diff = true;
        return key;
      });

      return diff;
    },
  },

  computed: {
    data() {
      return this.list.filter((item) => {
        if (
          this.isMasterclass !== null
          && this.isMasterclass !== item.isMasterclass.toString()
        ) return false;
        if (
          this.trackFilter !== null
          && this.trackFilter !== item.track.id
        ) return false;
        if (
          this.stageListFilter !== null
          && this.stageListFilter !== item.stage.id
        ) return false;
        return true;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.vdatetime
  width: 70px
  margin-right: 3px
  margin-left: 3px
</style>
