import Vue from 'vue';

export default {
  get: async () => Vue.http.get('events/schedule')
    .then((res) => res.json()),

  getById: async (id) => Vue.http.get(`events/schedule/${id}`)
    .then((res) => res.json()),

  delete: async (id) => Vue.http.delete(`events/schedule/${id}`)
    .then((res) => res.json()),

  upload: async (model) => Vue.http.post('events/schedule/upload', model)
    .then((res) => res.json()),

  save: async (model) => Vue.http.post('events/schedule', model)
    .then((res) => res.json()),

  update: async (model, id) => Vue.http.put(`events/schedule/${id}`, model)
    .then((res) => res.json()),

  updateSchedule: async (model, id) => Vue.http.put(`events/schedule/update/${id}`, model)
    .then((res) => res.json()),
};
